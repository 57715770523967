@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    textarea {
        border-width: 1px;
        @apply rounded p-2 focus:outline-none;
    }

    select {
        @apply border rounded focus:outline-none p-2;
    }
}

@layer components {
    .bg-custom-blue {
        background-color: #022A58;
    }
    
    .text-custom-blue {
        color: #022A58;
    }

    .menu {
        border-color: #022A58;
        border-left-width: 4px;
    }

    .header-border {
        border-bottom-width: 2px;
        @apply border-gray-100 shadow;
    }

    .border-b-blue {
        border-bottom-color: #022A58;
    }

    .btn {
        @apply text-center rounded h-10 px-3 space-x-1 flex justify-center items-center font-semibold transition-colors duration-150;
    }

    .btn-small {
        @apply text-center text-sm rounded h-8 px-3 space-x-1 flex justify-center items-center font-semibold transition-colors duration-150;
    }

    .btn-disabled {
        @apply bg-gray-500 bg-opacity-50;
    }

    .btn-primary {
        background-color: #C6A056;
        @apply text-white hover:bg-yellow-400;
    }

    .btn-secondary {
        background-color: #022A58;
        @apply text-white hover:bg-blue-900;
    }

    .btn-tertiary {
        color: #022A58;
        border-width: 1px;
        border-color: #022A58;
    }

    .active-page {
        @apply bg-gray-100 text-black rounded;
    }

    .inactive-page {
        @apply text-gray-400;
    }

    .active-step {
        @apply bg-gray-100;
        color: #022A58;
    }

    .active-client-step {
        background-color: #022A58;
        @apply text-white;
    }

    .input-text {
        @apply h-10 pl-3 focus:outline-none border rounded w-full;
    }

    .tooltip {
        border-width: 1px;
        @apply invisible fixed -mt-7 bg-white rounded text-xs text-gray-400 py-1 px-2 shadow-sm;
    }

    .has-tooltip:hover .tooltip {
        @apply visible z-50;
    }

    .error {
        @apply border rounded my-2 mx-5 px-5 py-2 border-red-500 bg-red-50 text-red-500 text-sm;
    }

    .badge {
        @apply text-xs rounded my-2 px-2 py-1 text-white bg-green-500;
    }

    .badge-sm {
        font-size: 9px;
        line-height: 8px;
        @apply py-1 font-semibold;
    }

    @variants hover {
        .active-menu {
            border-color: #C6A056;
            border-left-width: 4px;
            background-color: rgba(255, 255, 255, 0.1);
            @apply text-white;
        }

        .active-page {
            @apply bg-gray-100 text-black rounded;
        }
    }
}